<template>
  <div>
    <v-progress-linear v-model="$store.state.progress" color="blue-grey" height="25">
      <template>
        <strong v-if="$store.state.progress != 0"
          >{{ Math.ceil($store.state.progress) }}%</strong
        >
        <strong v-else>Cargando</strong>
      </template>
    </v-progress-linear>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["progress"]),
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.body {
  margin: 0;
  padding: 7.5% 0;

  display: flex;
  align-items: center;
  justify-content: center;
}
.loading {
  background: #fff;
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  border-radius: 50%;
  border-top: 10px solid #e74c3c;
  position: relative;
  animation: a1 2s linear infinite;
}
.loading::before,
.loading::after {
  content: "";
  width: 200px;
  height: 200px;
  position: absolute;
  left: 0;
  top: -10px;
  box-sizing: border-box;
  border-radius: 50%;
}
.loading::before {
  border-top: 10px solid #e67e22;
  transform: rotate(120deg);
}
.loading::after {
  border-top: 10px solid #3498db;
  transform: rotate(240deg);
}
.loading span {
  position: absolute;
  width: 200px;
  height: 200px;
  color: #000;
  text-align: center;
  line-height: 200px;
  animation: a2 2s linear infinite;
}

@keyframes a1 {
  to {
    transform: rotate(360deg);
  }
}
@keyframes a2 {
  to {
    transform: rotate(-360deg);
  }
}
</style>
